import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { ContentContainer } from "../components/containers/content-container";
import { Article } from "../components/article/article";

// Context
import { PageContext } from "../components/context/page-context";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  margin: 120px 0;

  & .tag-container {
    width: fit-content;
    margin: 0 auto;

    & h1 {
      border: 1px solid #bf4945;
      padding: 0 10px;

      font-size: 14px;
      line-height: 40px;

      text-transform: uppercase;

      color: #bf4945;
      background-color: #fff;

      text-align: center;
    }
  }
`;

const Writing = ({ data }) => {
  const [pageContext, setPageContext] = useContext(PageContext);

  useEffect(() => {
    setPageContext({
      pageTitle: `Writing`,
      pageColor: `#fff`,
      pageType: `page`,
    });
  }, []);

  const content = data.allPrismicWriting.nodes.map((item, index) => (
    <Article key={`single_tag_item_${index}`} item={item} index={index} />
  ));

  return (
    <>
      <PageSeo
        seoTitle={`Writing - George Vasey`}
        seoText={null}
        seoImage={null}
      />
      <Page>
        <ContentContainer>{content}</ContentContainer>
      </Page>
    </>
  );
};

export const query = graphql`
  {
    allPrismicWriting {
      nodes {
        tags
        data {
          title {
            richText
          }
          content_type {
            document {
              ... on PrismicContentType {
                id
                data {
                  content_type
                  color
                }
              }
            }
          }
        }
        url
      }
    }
  }
`;

export default withPrismicPreview(Writing);
